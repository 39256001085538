import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/Seo"
import PagePanel from "../components/PagePanel"
import Testimonial from "../components/Testimonial"
import { v4 as uuidv4 } from 'uuid'

class Page extends React.Component {

  componentDidMount() {
    this.props.setShowMenu(false);
    this.props.setCurrentPage(this.props.data.data.title);
    this.props.hasLoaded === false && this.props.setHasLoaded(true);
    this.props.cursorVisible === true && this.props.setCursorVisible(false);
  }

  render() {
    const {data} = this.props;
    let testimonialNum;

    let testimonials = data.data.testimonials && data.data.testimonials.map((t) => {
      return {quote: t.quote, credit: t.credit}
    })
    if (testimonials) {
      testimonialNum = Math.floor(Math.random() * testimonials.length)
    }
    return(
      <>
        <SEO title={`— ${data.data.title}`}/>
        <PagePanel key={uuidv4()} data={data.data}/>
        {console.log(testimonials)}
        {testimonials && <Testimonial key={uuidv4()} quote={testimonials[testimonialNum].quote} credit={testimonials[testimonialNum].credit}/>}
      </>
    )
  }
}

export const query = graphql`
    query($uri: String) {
      data(uri: {eq: $uri}) {
        url
        testimonials {
          quote
          credit
        }
        content {
          text
          image {
            local {
              childImageSharp {
                fluid(maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationHeight
                    presentationWidth
                }
              }
            }
          }
          caption
        }
        title
        uri
      }
    }
`;

export default Page;