import { Parser } from "html-to-react"
import React, { useState } from "react"
import { css } from "@emotion/core"
import { getRandomInt } from "../helpers"
import Draggable from "react-draggable"
import { IoIosClose } from "react-icons/io"
import { IconContext } from "react-icons"

const htmlToReactParser = new Parser()

const Testimonial = ({ quote, credit }) => {
  const [location, setLocation] = useState({
    x: getRandomInt(5, 20),
    y: getRandomInt(5, 30),
  })
  const [visible, setVisible] = useState(true)

  function handleCloseTestimonial(e) {
    e.preventDefault();
    setVisible(false);
  }

  const testimonial = css`
    position: fixed;
    display: ${visible ? "flex" : "none"};
    right: ${location.x}%;
    bottom: ${location.y}%;
    width: 300px;
    color: #ff7777;
    background: #f9f5f2;
    z-index: 2000;
    padding: 0.6rem 0rem 0.6rem 0.8rem;
    cursor: grab;
    @media screen and (max-width: 980px) {
      display: none;
    }
  `

  const text = css`
    p {
      font-size: 0.7rem;
      line-height: 1.3;
    }
    div {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  `

  return (
    <Draggable
      handle=".handle"
      defaultPosition={{ x: location.x, y: location.y }}
    >
      <div className="handle" css={testimonial}>
        <article css={text}>
          <div>
            {quote && htmlToReactParser.parse(quote)}
          </div>
          <div>
            {credit && htmlToReactParser.parse(credit)}
          </div>
        </article>
        <IconContext.Provider
          value={{
            className: "react-icons",
            style: {
              verticalAlign: "middle",
              width: "40px",
              height: "24px",
              cursor: "pointer",
              marginTop: "-4px",
              flexShrink: "0"
            },
          }}
        >
          <IoIosClose onClick={handleCloseTestimonial} />
        </IconContext.Provider>
      </div>
    </Draggable>
  )
}

export default Testimonial
