import { Parser } from "html-to-react"
import React from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { v4 as uuidv4 } from 'uuid'

const panel = css`
  position: absolute;
  padding: 5rem 1.4rem 5rem;
  top: 0px;
  left: 0px;
  width: 50vw;
  min-height: 100vh;
  background: #4C4141;
  p {
    max-width: 30rem;
    margin-bottom: 0.5em;
    line-height: 1.4;
    @media screen and (max-width: 1200px) {
      font-size: 1.4rem;
      max-width: 36rem;
      margin-bottom: 1em;
    }
    @media screen and (max-width: 760px) {
      font-size: 1rem;
      max-width: 36rem;
      margin-bottom: 1em;
    }
  }
  a {
    color: #F5D9D0;
    text-decoration: underline;
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.2rem;
  }
  a:hover {
    color: #ff7777;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  & ::after {
    content: '';
    display: inline-block;
    width: 50vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0px;
  }
`

const image = css`
  margin-top: 3rem;
  span {
    display: inline-block;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  @media screen and (max-width: 760px) {
    margin-top: 2rem;
  }
`

const pageTitle = css`
  display: none;
  @media screen and (max-width: 760px) {
    display: block;
    padding-bottom: 1rem;
  }
`

const htmlToReactParser = new Parser()

class PagePanel extends React.Component {

  render() {
    const pageContent = this.props.data.content.map((el) => {
      if (el.text) {
        return <div key={uuidv4()}>{htmlToReactParser.parse(el.text)}</div>
      } else if (el.image) {
        const fluid = el.image.local.childImageSharp.fluid;
        return (
          <div key={uuidv4()} css={image}>
            <Img key={uuidv4()} fluid={fluid} css={css`${fluid.presentationHeight > fluid.presentationWidth && 'width: 61.8%; margin: 0 auto;'}`} />
            {el.caption && <span key={uuidv4()}>{el.caption}</span>}
          </div>
        )
      }
      return null;
    })

    return(
        <article  css={panel}>
        <h1 css={pageTitle}>{this.props.data.title}</h1>
          {pageContent && pageContent}
        </article>
    )
  }
}

export default PagePanel